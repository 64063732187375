<script>

/**
 * Contacts-list component
 */
export default {
  data() {
    return {}
  },
  computed: {
    // a computed getter
    tryout: function () {
      return this.$store.getters["koreksi/selected"];
    },
    loading: function () {
      return this.$store.getters["koreksi/loading"];
    }
  },
  methods : {
    mulai(){
        this.$store.dispatch("koreksi/startKoreksi")
    },
    backToList(){
        this.$store.commit("koreksi/SET_STATE","LIST")
    }
  }
};
</script>

<template>
    <div class="card" v-if="tryout !=null">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="@/assets/images/startKoreksi.png" width="400px" alt class="img-thumbnail" />
                <p class="my-2">Apakah Anda Yakin Ingin Melanjutkan Koreksi Dari Ujian User <b>{{tryout.name}}</b> ? </p>
                <div>
                    <button class="btn btn-sm btn-primary mx-2" @click="mulai()">Ya, Mulai Koreksi</button>
                    <button class="btn btn-sm btn-danger mx-2" @click="backToList()">Tidak, Kembali Ke List</button>
                </div>
            </div>
        </div>
    </div>
</template>