<script>

/**
 * Contacts-list component
 */
// import Swal from "sweetalert2";
const moment = require('moment');
export default {
  data() {
    return {
      title: "User List",
    };
  },
  computed : {
    tryout: function () {
      return this.$store.getters["koreksi/selected"];
    },
    matpels() {
      return this.$store.getters["koreksi/matpels"];
    },
    loading: function () {
      return this.$store.getters["koreksi/loading"];
    }
  },
  mounted(){
      this.$store.dispatch("koreksi/getMatpel");
  },
  methods : {
    firstChar(str){
        console.log(str)
        return str.charAt(0).toUpperCase();
    },
    buka(row){
      this.$store.commit("koreksi/SET_KOREKSI",row)
      this.$store.dispatch("koreksi/bukaSoal")
    },
    parseData(str){
        return moment(str).format('DD, MMMM - YYYY');
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },
    mulaiKoreksi(row){
        this.$store.commit("koreksi/SET_SELECTED",row)
        this.$store.commit("koreksi/SET_STATE","DIALOG")
    },
    backToList(){
        this.$store.commit("koreksi/SET_STATE","LIST")
    }
  }
};
</script>

<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <div class="card" v-if="tryout != null">
                    <div class="card-body">
                    <h2 class="card-title">Informasi Ujian</h2><br>
                        <p>Nama User : <b>{{tryout.name}}</b></p>
                        <hr>
                        <p>Nama Paket Ujian : <b>{{tryout.nama_paket}}</b></p>
                        <hr>
                        <p>Tanggal Ujian : <b>{{parseData(tryout.tgl)}}</b></p>
                        <hr>
                        <p>Total Nilai : <b>0</b></p>
                        <hr>
                        <button class="btn btn-sm btn-danger mx-2" @click="backToList()">Kembali ke List</button>
                        <button class="btn btn-sm btn-light mx-2">Koreksi Selesai</button>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="row" v-if="matpels != null">
                    <div class="col-md-4" v-for="matpel in matpels" :key="matpel.id_tryoutDetail">
                        <div class="card mini-stats-wid">
                        <div class="card-body">
                            <div class="media">
                            <div class="media-body">
                                <p class="text-muted font-weight-medium">{{matpel.nama_matpel}}</p>
                                <h4 class="mb-0">{{matpel.nilai}}</h4>
                            </div>
                            <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
                                <span class="avatar-title">
                                <i class="bx bx-book-content font-size-24"></i>
                                </span>
                            </div>
                            </div>
                            <div class="mt-1">
                                <button v-show="matpel.statusKoreksi == 0" @click="buka(matpel)" class="btn btn-primary btn-sm">
                                    Buka
                                    <i class="mdi mdi-arrow-right ml-1"></i>
                                </button>
                                <a v-show="matpel.statusKoreksi == 2" href class="btn btn-warning btn-sm">
                                    Lanjut
                                    <i class="mdi mdi-arrow-right ml-1"></i>
                                </a>
                            </div>
                        </div>
                        <!-- end card-body -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
