<script>
    // @ts-ignore
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import DatePicker from "vue2-datepicker";
// import Swal from "sweetalert2";
/**
 * Product-checkout Component
 */

import ListData from "@/router/views/koreksi/lists";
import Dialog from "@/router/views/koreksi/dialog";
import Matpel from "@/router/views/koreksi/matpel";
import SoalKoreksi from "@/router/views/koreksi/soal";
export default {
   page: {
    title: "Paket Tryout",
    meta: [{ name: "description", content: 'Paket Tryout on Tryout Apps' }]
  },
  components: { Layout, PageHeader,ListData,Dialog,Matpel,SoalKoreksi},
  data() {
    return {
        title: "List Ujian Users",
        items: [
            {
            text: "List Ujian Users",
            active: true
            }
        ],
    };
  },
  computed : {
    state: function () {
      return this.$store.getters["koreksi/states"];
    },
    notif: function () {
      return this.$store.getters["koreksi/notif"];
    }
  },
  methods : {
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
        <b-alert show dismissible fade v-show="notif != null">{{notif}}</b-alert>
        <div class="col-md-12"  v-show="state == 'LIST'">
            <ListData />
        </div>
        <div class="col-md-12"  v-show="state == 'DIALOG'">
            <Dialog />
        </div>
        <div class="col-md-12"  v-show="state == 'MATPEL'">
            <Matpel />
        </div>
        <div class="col-md-12"  v-show="state == 'SOAL'">
            <SoalKoreksi />
        </div>
    </div>
  </Layout>
</template>
