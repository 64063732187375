<script>
    // @ts-ignore

import VueAudio from 'vue-audio';
import vuePlayer  from  '@algoz098/vue-player'
const Koreksi = require('./../../../api/koreksi');
// import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
/**
 * Product-checkout Component
 */
const today = new Date();
today.setHours(0, 0, 0, 0);
export default {
   page: {
    title: "Koreksi",

  },
  components: { VueAudio, vuePlayer },
  data() {
    return {
        title: "Koreksi",
        items: [
            {
            text: "Koreksi",
            active: true
            }
        ],
        Koreksi : new Koreksi(),
        lists : [],
        states :Koreksi.states(),
        start_time : "00:00",
        end_time : "24:00",
        total : 0,
        offset : 0,
        limit  : 10,
        paginations : [],
        pagination : null,
        jawaban : [],
        point : 0
    };
  },
  computed : {
        start_timeOption(){
            return {start:'00:00',step:'01:00',end:this.end_time}
        },
        end_timeOption(){
            return {start:this.start_time,step:'01:00',end:'24:00'}
        },
        tanggalDefault() {
            return new Date()
        },
        soals() {
          return this.$store.getters["koreksi/soals"];
        },
        loading: function () {
          return this.$store.getters["koreksi/loading"];
        }
  },
  mounted(){

  },
  methods : {
        notBeforeToday(date) {
            return date < today;
        },
        getList(){
            this.states.loading = true;
            this.Koreksi.getList(this.states)
            .then(res=>{
                this.states = res;
            })
            .catch((e)=>this.states = e)
        },
        back(){
            this.jawaban = null;
            this.$store.commit("koreksi/SET_STATE","MATPEL")
        },
        simpan(){
          console.log(this.jawaban);
          if (this.jawaban < 1) {
            console.log('harus di isi dulu');
            Swal.fire("hmm!", "harus di isi dulu.", "warning");
          }else{
            this.jawaban.forEach((item) => {
              console.log(item.id);
              // this.Koreksi.kumpulJawabanPondok(item.id,item.status)
              // .then((res)=>{
              //   if(!res.success){
              //     console.log(res.data);
              //     this.setError(true,res.data)
              //   }else{
              //     console.log(res.data);
              //     this.message = res.data;
              //     Swal.fire("sukses!", "soal selesai dikoreksi!.", "success");
              //     this.getList();
              //   }
              // })
              // .catch((err)=>{
              //   this.setError(true,err);
              // })
            });
          }
        },
        selesaiKoreksi(koreksi){
          this.Koreksi.selesaiKoreksi(koreksi.id_tryoutDetail)
          .then((res)=>{
            if(!res.success){
              console.log(res.data);
              this.setError(true,res.data)
            }else{
              console.log(res.data);
              this.message = res.data;
              Swal.fire("sukses!", "soal selesai dikoreksi!.", "success");
              this.getList();
            }
          })
          .catch((err)=>{
            this.setError(true,err);
          })
        },
        showMatpel(koreksi){
            console.log(koreksi.id);
            this.states.isMatpel = true;
            this.Koreksi.getMatpel(this.states,koreksi.id);
        },
        showKoreksi(koreksi){
            console.log(koreksi.id_tryoutDetail);
            this.states.isMatpel = false;
            this.states.isCreate = true;
            this.Koreksi.getKoreksi(this.states,koreksi.id_tryoutDetail);
        },
        cancel(){
            this.states = this.Koreksi.reset()
        },
        simpanNilai(){
          this.$store.commit("koreksi/SET_POINTS",this.point)
          this.$store.dispatch("koreksi/kumpulkanNilai")
          // this.Koreksi.simpanNilaiKoreksi("1")
          // .then((res)=>{
          //   if(!res.success){
          //     console.log(res.data);
          //     this.setError(true,res.data)
          //   }else{
          //     console.log(res.data);
          //     this.message = res.data;
          //     Swal.fire("sukses!", "soal selesai dikoreksi!.", "success");
          //     this.getList();
          //   }
          // })
          // .catch((err)=>{
          //   this.setError(true,err);
          // })
        },
        changePagination(pagination) {
            this.offset = pagination.offset
            this.limit = pagination.limit
            this.pagination = pagination
            this.getKoreksi();
        },
  }
};
</script>

<template>
    <div>
        <div class="row">
          <div class="col-md-3">
              <div class="card">
                  <div class="card-body">
                    <button class="btn btn-danger mx-2 " @click="back()">Kembali</button>
                  </div>
              </div>

          </div>
            <div class="col-md-9">
              <div class="card">
                <div class="card-body">
                  <h2 class="">Soal </h2>

                  <hr>
                  <div v-if="soals == null" class="d-flex justify-content-center align-item-center p-4 m-4">
                    <img src="@/assets/images/empty.png" class="img-fluid p-4 m-4"/>
                  </div>
                  <div v-else class="m-2" v-for="soal in soals" :key="soal.id_tryoutDetailSoals">
                    <div class="d-flex flex-row justify-content-between">

                      <p v-html="soal.soal"></p>

                    </div>
                    <p><b>Jawaban User : </b></p>
                    <div class="bg-light p-2">
                      <p>{{soal.jawaban_user}}</p>
                      <div v-if="soal.jawaban_user != null">
                        <div v-if="soal.jawaban_user.endsWith('.aac')" id="audio">
                          <VueAudio :file="'http://103.41.207.247:3000/'+soal.jawaban_user"></VueAudio>
                        </div>
                        <div v-if="soal.jawaban_user.endsWith('.jpg')">
                          <img :src="'http://103.41.207.247:3000/'+soal.jawaban_user" alt="" height="300px" width="300px">
                        </div>
                        <div v-if="soal.jawaban_user.endsWith('.mp4')">
                          <vue-player :src="'http://103.41.207.247:3000/'+soal.jawaban_user" title="this is a title" v-model="playing"></vue-player>
                        </div>
                      </div>
                    </div>
                    <br>
                    <p><b>PEMBAHASAN : </b></p>
                    <div v-if="soal.jawaban_benar.endsWith('.mp3')">
                      <vue-audio :file="'http://103.41.207.247:3000/'+soal.jawaban_benar"></vue-audio>
                    </div>
                    <div v-else-if="soal.jawaban_benar.endsWith('.jpg')">
                      <div class="img img-responsive">
                          <div v-html="soal.jawaban_benar"  v-if="soal.jawaban_benar"><b>{{index+1}}. </b></div>
                          <img :src="'http://103.41.207.247:3000/'+soal.jawaban_benar" v-else alt="" height="300px" width="300px">
                      </div>
                    </div>
                    <div v-else-if="soal.jawaban_benar.endsWith('.png')">
                      <div class="img img-responsive">
                          <div v-html="soal.jawaban_benar"  v-if="soal.jawaban_benar"><b>{{index+1}}. </b></div>
                          <img :src="'http://103.41.207.247:3000/'+soal.jawaban_benar" v-else alt="" height="300px" width="300px">
                      </div>
                    </div>
                    <div v-else-if="soal.jawaban_benar.endsWith('.docx')">
                      <a :href="'http://103.41.207.247:3000/'+soal.jawaban_benar">Download</a>
                    </div>
                    <div v-else>
                      <a :href="'http://103.41.207.247:3000/'+soal.jawaban_benar">Download</a>
                    </div>
                    <br>
                    <!-- <p><b>Status Jawaban : </b></p>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" v-model="jawaban" v-bind:value="{id :soal.id_tryoutDetailSoals, status: 1}" id="inlineCheckbox1">
                      <label class="form-check-label" for="inlineCheckbox1">Benar</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" v-model="jawaban" id="inlineCheckbox2" v-bind:value="{id :soal.id_tryoutDetailSoals, status: 0}">
                      <label class="form-check-label" for="inlineCheckbox2">Salah</label>
                    </div> -->
                    <!-- <hr> -->
                    <br>
                    <hr>
                  </div>
                  <div class="row  d-flex flex-column justify-content-center align-items-center">
                      <div class="col-md-5">
                          <div class="form-group">
                              <label class="control-label">Nilai <span class="text-danger">*</span></label>
                              <input type="number" class="form-control" v-model="point" placeholder="Nilai">
                          </div>
                          <div class="form-group">
                              <button class="btn btn-sm btn-primary" @click="simpanNilai()"> Save nilai{{point}}</button>
                          </div>
                      </div>
                  </div>
                </div>
              </div>

            </div>
        </div>
    </div>
</template>
