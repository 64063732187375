<script>

/**
 * Contacts-list component
 */
// import Swal from "sweetalert2";
const moment = require('moment');
export default {
  data() {
    return {
      title: "User List",
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: true,
        fields: [
            { key: "murid",label:"Nama User", sortable: true },
            { key: "paket",label:"Paket Ujian", sortable: true },
            { key: "tgl",label:"Tanggal Ujian", sortable: true },
            { key: "status",label:"Status", sortable: true },
            { key: "guru",label:"Guru", sortable: true },
            { key: "action",label:"Action", sortable: false },
        ]
    };
  },
  computed : {
    datas: function () {
      return this.$store.getters["koreksi/items"];
    },
    rows() {
        return this.datas.length;
    },
    loading: function () {
      return this.$store.getters["koreksi/loading"];
    }
  },
  mounted(){
      this.$store.dispatch("koreksi/getItems");
  },
  methods : {
    firstChar(str){
        console.log(str)
        return str.charAt(0).toUpperCase();
    },
    parseData(str){
        return moment(str).format('DD, MMMM - YYYY');
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },
    mulaiKoreksi(row){
        this.$store.commit("koreksi/SET_SELECTED",row)
        this.$store.commit("koreksi/SET_STATE","DIALOG")
    },
    lanjut(row){
      console.log(row);
        this.$store.commit("koreksi/SET_SELECTED",row)
          this.$store.dispatch("koreksi/LanjutKoreksi")
    }
  }
};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <div class="row mt-4">
                <div class="col-sm-8 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                        </label>
                    </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ml-2"
                            ></b-form-input>
                        </label>
                    </div>
                </div>
                <!-- End search -->
            </div>
                <!-- Table -->
            <div class="table-responsive mb-0">
                <b-table
                    :items="datas"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                    <template #cell(murid)="row">
                      <h5 class="font-size-14 mb-1">
                        <a href="#" class="text-dark">{{row.item.name}}</a>
                      </h5>
                    </template>
                    <template #cell(paket)="row">
                        <p>{{row.item.nama_paket}}</p>
                    </template>
                    <template #cell(tgl)="row">
                        <p>{{parseData(row.item.tgl)}}</p>
                    </template>
                    <template #cell(status)="row">
                        <h4 v-show="row.item.id_guru == null">Belum Di Koreksi <b-badge  variant="primary">New</b-badge></h4>
                        <h4 v-show="row.item.id_guru != null && row.item.statusKoreksi == 2"><b-badge  variant="warning">Sedang di Koreksi</b-badge></h4>
                        <h4 v-show="row.item.id_guru != null && row.item.statusKoreksi == 1"><b-badge  variant="success">Selesai Di Koreksi</b-badge></h4>
                    </template>
                    <template #cell(guru)="row">
                        <h4 v-if="row.item.id_guru == null">Belum Di Koreksi <b-badge  variant="primary">New</b-badge></h4>
                        <p v-else>{{row.item.guru}}</p>
                    </template>
                    <template #cell(action)="row">
                        <button v-show="row.item.statusKoreksi == 0" @click="mulaiKoreksi(row.item)" class="btn btn-primary btn-sm  mx-1"  v-b-tooltip.hover title="Mulai Koreksi" >Mulai Koreksi</button>
                        <button v-show="row.item.statusKoreksi == 1" class="btn btn-success btn-sm  mx-1"  v-b-tooltip.hover title="Sudah Di Koreksi" >Sudah Selesai Di Koreksi</button>
                        <button v-show="row.item.statusKoreksi == 2" @click="lanjut(row.item)"  class="btn btn-warning btn-sm  mx-1"  v-b-tooltip.hover title="Lanjut Koreksi" >Lanjut Koreksi</button>
                    </template>
                </b-table>
            </div>
            <div class="row">
                <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
